import React from 'react';
import { useAuth } from '../../context/useAuth';
import { Navigate } from 'react-router-dom';

export const UserProtected = ({ element }) => {
    const { authState } = useAuth();
    if (authState?.user) {
        return <>{element}</>;
    }

    return <Navigate to={"/login"} replace />;
};

export const AuthProtected = ({ element }) => {
    const { authState } = useAuth();
    if (authState?.user && authState?.token) {
        return <Navigate to={"/"} replace />;
    }

    return <>{element}</>;
};