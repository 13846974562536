import React, { useCallback, useEffect, useMemo, useState } from "react";
import Consultation from "./Consultaion/Consultation";
import { getConsultatoinAppointments } from "../../utils/bookConsultation/ConsultationUtils";
import RegisterModal from "./RegisterModal";
import AppointmentModal from "./AppointmentModal";
import _ from "lodash";
import { Loader, Pagination, SearchInput } from "../Common";
import LisOfDoctors from "./ListOfDoctors";

const limit = 10;
const AppointmentsMainSection = ({ booking_type= ""}) => {
  const [open, setOpen] = useState(false);
  const [consultationAppointments, setConsultaionAppointments] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [openDoctorList, setOpenDoctorList] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    next: null,
    prev: null,
    total: 1,
  });
  const setError = useState({ global: "" })[1];
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const fetchAppointments = useCallback(
    async (
      search,
      page = 1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    ) => {
      setLoading(true);
      try {
        const response = await getConsultatoinAppointments(
          true,
          search,
          page,
          selectedDoctor?._id,
          start_date,
          end_date,
          booking_type
        );
        const { data: appointments, pagination } = response?.data?.data;
        setConsultaionAppointments(appointments);
        setPagination(pagination);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const debouncedFetchAppintments = useMemo(
    () =>
      _.debounce(
        async (
          search,
          page = 1,
          selectedDoctor,
          start_date,
          end_date,
          booking_type
        ) => {
          await fetchAppointments(
            search,
            page,
            selectedDoctor,
            start_date,
            end_date,
            booking_type
          );
        },
        300
      ),
    [fetchAppointments]
  );

  useEffect(() => {
    debouncedFetchAppintments(
      searchQuery,
      pagination?.current,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    debouncedFetchAppintments,
    pagination?.current,
    selectedDoctor,
    start_date,
    end_date,
    booking_type
  ]);

  useEffect(() => {
    debouncedFetchAppintments(
      searchQuery,
      1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
  }, [
    debouncedFetchAppintments,
    searchQuery,
    selectedDoctor,
    start_date,
    end_date,
    booking_type
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      debouncedFetchAppintments(
        searchQuery,
        pagination?.current,
        selectedDoctor,
        start_date,
        end_date,
        booking_type
      );
    }, 5 * 60 * 1000); 

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedFetchAppintments,
    searchQuery,
    pagination?.current,
    selectedDoctor,
    start_date,
    end_date,
    booking_type
  ]);

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current: newPage }));
  };

  const handleSearchQueryChange = (newSearchQuery) => {
    const value = newSearchQuery;
    if (value.length === 1 && value === " ") {
      return;
    }
    const modifiedValue = value.replace(/\s+$/, " ");
    setSearchQuery(modifiedValue);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchAppintments(
      modifiedValue,
      1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
  };

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchAppintments(
      searchQuery,
      1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchAppintments(
      searchQuery,
      1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setPagination((prev) => ({ ...prev, current: 1 }));
    debouncedFetchAppintments(
      searchQuery,
      1,
      selectedDoctor,
      start_date,
      end_date,
      booking_type
    );
  };

  const [isRevisit, setIsRevisit] = useState(false);
  const [isFollowup, setIsFollowup] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);

  const [mode, setMode] = useState("");
  const closeFollowAndRevisit = (value) => {
    setIsRevisit(value);
    setIsFollowup(value);
    setPatientDetails(null);
    setMode("");
    setOpen(false);
  };

  return (
    <>
      {openDoctorList && (
        <LisOfDoctors
          setOpen={setOpenDoctorList}
          selected={selectedDoctor}
          setSelected={handleDoctorSelect}
        />
      )}
      {(open || patientDetails) && (
        <AppointmentModal
          appointmentDetails={patientDetails}
          is_follow_up={isFollowup}
          is_re_visit={isRevisit}
          setOpen={closeFollowAndRevisit}
          setDatasFn={() => fetchAppointments(searchQuery, pagination?.current)}
          mode={mode}
        />
      )}
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full flex-col justify-end items-center gap-3">
          {!booking_type && <div className="flex justify-end w-full">
            <button
              onClick={() => setOpen(true)}
              className={`flex px-3 border border-primary py-1 rounded-md bg-primary text-white`}
            >
              Appointment
            </button>
          </div>}
          <div className="flex gap-3 w-full">
            <button
              onClick={() => setOpenDoctorList(true)}
              className={`flex px-3 border border-primary py-1 rounded-md bg-primary text-white`}
            >
              Doctors
            </button>
          </div>
          {!open && !patientDetails && !openDoctorList && (
            <div className="flex w-full justify-start mt-3 ">
              <div className="flex gap-3 w-md justify-start">
                <div className="lable-and-filed">
                  <label
                    htmlFor={"Start"}
                    className={`text-[14px] text-shadow-black text-black`}
                  >
                    {"Start"}
                  </label>
                  <div
                    className="input-coantiner"
                    onClick={() => setError({ global: "" })}
                  >
                    <input
                      type="date"
                      value={start_date}
                      onChange={(e) => handleStartDate(e.target.value)}
                      className="input"
                    />
                  </div>
                </div>
                <div className="lable-and-filed">
                  <label
                    htmlFor={"End"}
                    className={`text-[14px] text-shadow-black text-black`}
                  >
                    {"End"}
                  </label>
                  <div
                    className="input-coantiner"
                    onClick={() => setError({ global: "" })}
                  >
                    <input
                      type="date"
                      value={end_date}
                      onChange={(e) => handleEndDate(e.target.value)}
                      className="input"
                    />
                  </div>
                </div>
                {(start_date?.length > 0 || end_date?.length > 0) && (
                  <div className="flex w-fit h-fit  mb-2 bg-slate-300 rounded">
                    <button
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                      }}
                      className="text-xs translate-x-1 -translate-y-1 hover:translate-x-0 hover:translate-y-0 bg-white py-0.5 px-1 rounded  duration-500 text-primary border"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={handleSearchQueryChange}
            />
          </div>
        </div>
        {loading ? (
          <div className="flex mt-10">
            <Loader />
          </div>
        ) : (
          <>
            <Consultation
              appointments={consultationAppointments}
              setAppointmentsFn={() =>
                fetchAppointments(searchQuery, pagination?.current)
              }
              setIsRevisit={setIsRevisit}
              setIsFollowup={setIsFollowup}
              setPatientDetails={setPatientDetails}
              setMode={setMode}
              currentPage={pagination?.current}
              limit={limit}
            />
            {!open && !patientDetails && (
              <Pagination
                current={pagination?.current}
                total={pagination?.total}
                onPageChange={handlePageChange}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AppointmentsMainSection;
