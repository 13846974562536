import { useState } from "react";
export const useForm = (initialState) => {
    const [state, setState] = useState(initialState);
    const handleInput = (event) => {
        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };
    const clearForm = () => setState(initialState);
    const setValues = (values) => setState(values);
    return [state, handleInput, clearForm, setValues];
};

