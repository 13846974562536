import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackSharp } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import { getSchedule } from "../../../utils/scheduleUtils/scheduleUtils";

// Define common styles
const buttonStyle =
  "flex border text-xs cursor-pointer border-primary rounded-md w-[80px] h-[25px] justify-center items-center";
const bookedStyle = "bg-red-600 text-white";
const selectedStyle = "bg-primary text-white";
const defaultStyle = "text-primary bg-white";

const DoctorData = ({ data, setData, doctor, setErr, is_re_visit = false }) => {
  const { _id, name, doctor_id, doctor_image } = doctor;
  const { date = null, time = null } = data || {};
  const [schedules, setSchedules] = useState([]);
  const [dateStartIndex, setDateStartIndex] = useState(0);

  const setSchedulesFn = useCallback(() => {
    getSchedule(doctor?._id)
      .then((res) => setSchedules(res.data.data))
      .catch((err) => console.log(err));
  }, [doctor?._id]);

  useEffect(() => {
    setSchedulesFn();
  }, [setSchedulesFn]);

  const filterFutureDates = (schedules) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return schedules.filter(schedule => new Date(schedule.date) >= today);
  };

  const futureDates = filterFutureDates(schedules?.[0]?.schedules || []);

  const handleShowNextDates = () => {
    setDateStartIndex(prev => Math.min(prev + 7, futureDates.length - 7));
  };

  const handleShowPreviousDates = () => {
    setDateStartIndex(prev => Math.max(prev - 7, 0));
  };

  const renderScheduleButton = (slot) => (
    <button
      key={slot?._id}
      disabled={slot.isBooked}
      onClick={() => handleButtonClick(slot.time)}
      className={`${buttonStyle} ${
        slot.isBooked
          ? bookedStyle
          : slot.time === time
          ? selectedStyle
          : defaultStyle
      }`}
    >
      {slot?.time}
    </button>
  );

  const handleButtonClick = (slotTime) => {
    setErr({ global: "" });
    setData({
      doctor,
      date,
      time: slotTime,
    });
  };

  return (
    <div
      className={`flex w-full h-fit rounded-md border p-2 gap-3 mb-2 ${
        _id === (data?.doctor?._id ?? "") ? "bg-slate-100" : "bg-white"
      }`}
    >
      <img
        src={doctor_image}
        className="w-[130px] min-w-[130px] max-w-[130px] border shadow-md max-h-[130px] rounded-md object-contain flex-shrink-0"
        alt="doctor_image"
      />
      <div className="flex flex-col gap-2 w-full">
        <span className="text-base">{name}</span>
        <span className="text-sm">{doctor_id}</span>
        <span className="text-sm flex gap-2">
          <span>Consultation Fee</span> -{" "}
          <span
            className={`${
              !doctor?.price ? "text-red-500" : "text-black"
            } font-normal flex gap-0.5 items-center`}
          >
            {doctor?.price ? (
              <>
                <FaRupeeSign fontSize={12} /> {doctor.price}
              </>
            ) : (
              "Not Added Yet!"
            )}
          </span>
        </span>
        <span className="text-black text-sm font-semibold w-full flex gap-10">
          <span className={"flex"}>
            {date ? `Selected Date - ${date.date}` : "Available Dates:"}
          </span>
          <button
            onClick={() => {
              setData({
                date: null,
                time: null,
                doctor: is_re_visit ? doctor : null,
              });
              setDateStartIndex(0);
            }}
            className={`border border-primary px-2 rounded-md flex gap-1 items-center ${
              date ? "flex" : "hidden"
            }`}
          >
            <IoArrowBackSharp />
            Back
          </button>
        </span>

        {!date && (
          <div className="flex flex-col gap-2">
            {futureDates.length > 0 ? (
              <>
                <div className="flex flex-wrap gap-2">
                  {futureDates
                    .slice(dateStartIndex, dateStartIndex + 7)
                    .map((data) => (
                      <button
                        key={data?._id}
                        onClick={() => {
                          setErr({ global: "" });
                          setData({ date: data, doctor });
                        }}
                        className={buttonStyle}
                      >
                        {data?.date}
                      </button>
                    ))}
                </div>
                <div className="flex justify-between">
                  {dateStartIndex > 0 && (
                    <button
                      onClick={handleShowPreviousDates}
                      className="text-primary text-sm underline"
                    >
                      Previous Dates
                    </button>
                  )}
                  {dateStartIndex + 7 < futureDates.length && (
                    <button
                      onClick={handleShowNextDates}
                      className="text-primary text-sm underline"
                    >
                      Next Dates
                    </button>
                  )}
                </div>
              </>
            ) : (
              <span className="text-red-500 text-sm">No dates available</span>
            )}
          </div>
        )}

        {date && (
          <>
            <span className="text-black text-sm font-semibold w-full flex">
              Available Slots:
            </span>
            <div className="flex flex-wrap gap-2">
              {data?.date?.slots?.map((slot) => renderScheduleButton(slot))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DoctorData;
