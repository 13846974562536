import React from 'react'

const NoDataFound = ({data}) => {
  return (
    <div className="flex w-full h-full justify-center items-center font-semibold p-5">
      No {data} found !
    </div>
  );
}

export default NoDataFound