import React from "react";
import "./SelectOption.css";

const SelectOption = ({
  items,
  selectedItem,
  name,
  onItemChange,
}) => {
  return (
    <>
      <select
        className="select-option"
        value={selectedItem}
        name={name}
        onChange={onItemChange}
      >
        {items?.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectOption;
