import React, { useState } from "react";
import { TextInput } from "../../Common/FormComponents";
import { useForm } from "../../../hooks";
import { validateLoginForm } from "../../../utils/loginUtils/validateLogin";
import { handleLogin, verifyEmail, verifyOtp } from "../../../utils/loginUtils/loginUtils";
import PasswordInput from "../../Common/FormComponents/PasswordInput/PasswordInput";
import { useNavigate } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import OtpField from "./OtpFeild";
import NewPasswordField from "./NewPasswordField";
import { useAuth } from "../../../context/useAuth";

const LoginForm = () => {
  const [timeLeft, setTimeLeft] = useState(120); 
  const { setAuthState } = useAuth();
  const navigate = useNavigate();
  const initialState = {
    adminId: "",
    password: "",
    email: "",
    otp: "",
    newPassword: "",
    token: "",
  };
  const [loginData, handleLoginData, clearForm, setValue] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    if (validateLoginForm(loginData, setErr)) {
      setLoading(true);
      handleLogin(loginData, setErr)
        .then((res) => {
          const { token, user } = res?.data?.data;
          setAuthState({ user, token });
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };
  const handleVerifyEmail = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(loginData.email)) {
      setErr({ global: "Invalid Email" });
      return;
    }
    setErr({ global: "" });
    setLoading(true);
    verifyEmail(loginData.email, setErr).then(res => {
      setEmailVerfied(true);
      setTimeLeft(120);
    }).catch(err => console.log(err)).finally(() => { setLoading(false) });
  }
  const [forgot, setForgot] = useState(false);
  const [emailVerified, setEmailVerfied] = useState(false);
  const [otpVerfied, setOtpVerified] = useState(false);
  const handleOtpSubmit = (enteredOtp) => {
    if (!/^.{6}$/.test(enteredOtp)) {
      setErr({ global: "Invalid Otp" });
      return;
    }
    setErr({ global: "" });
    setLoading(true);
    verifyOtp(loginData.email, enteredOtp, setErr)
      .then((res) => {
        const { token } = res?.data;
        setValue({ ...loginData, token });
        setOtpVerified(true)
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className="flex w-full h-fit justify-center items-center pt-5 font-extrabold text-2xl">
        Reception Dashboard
      </div>
      <div className="flex w-full h-full flex-col md:flex-row">
        <div className="w-full h-[100px] md:h-full justify-center items-center flex ">
          <img
            src="/assets/images/logo.png"
            className="object-contain w-1/2 h-full"
            alt=""
          />
        </div>
        {!forgot && (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex w-full h-full justify-center items-center"
          >
            <div className="flex w-4/5 lg:w-1/2 h-fit flex-col gap-5 px-10 lg:px-0 ">
              <TextInput
                data={loginData}
                handleInput={handleLoginData}
                name="ID or Email"
                formName={"adminId"}
                setError={setErr}
                labelColor="text-black"
              />
              <PasswordInput
                data={loginData}
                handleInput={handleLoginData}
                name="Password"
                formName={"password"}
                setError={setErr}
                labelColor="text-black"
              />
              <span
                onClick={() => {
                  setErr({ global: "" });
                  clearForm();
                  setForgot(true);
                }}
                className="w-full flex justify-end text-sm cursor-pointer hover:text-primary"
              >
                forgot password ?
              </span>
              {err.global && (
                <div className="flex w-full h-fit text-red-600 justify-center items-center font-semibold text-sm">
                  {err.global}
                </div>
              )}
              <button
                disabled={loading}
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="w-full h-fit py-1 justify-center items-center px-2  text-white  rounded-md bg-primary"
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </form>
        )}
        {forgot && !emailVerified && (
          <VerifyEmail
            err={err}
            handleLoginData={handleLoginData}
            handleSubmit={handleVerifyEmail}
            loading={loading}
            loginData={loginData}
            setEmailVerfied={setEmailVerfied}
            setErr={setErr}
            setForgot={setForgot}
            setLoading={setLoading}
            clearForm={clearForm}
          />
        )}
        {forgot && emailVerified && !otpVerfied && (
          <OtpField
            handleSubmit={handleOtpSubmit}
            loading={loading}
            clearForm={clearForm}
            err={err}
            setErr={setErr}
            setForgot={setForgot}
            setEmailVerfied={setEmailVerfied}
            length={6}
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            handleResendOtp={handleVerifyEmail}
          />
        )}
        {forgot && emailVerified && otpVerfied && (
          <NewPasswordField
            err={err}
            handleLoginData={handleLoginData}
            loading={loading}
            loginData={loginData}
            setEmailVerfied={setEmailVerfied}
            setErr={setErr}
            setForgot={setForgot}
            setLoading={setLoading}
            setOtpVerified={setOtpVerified}
            clearForm={clearForm}
            handleResendOtp={handleVerifyEmail}
          />
        )}
      </div>
    </>
  );
};

export default LoginForm;
