import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const Loader = () => {
  return (
    <div className="flex w-full h-full justify-center items-center font-semibold gap-2">
      <span className="animate-spin"><AiOutlineLoading3Quarters /></span>
      <span>Loading...</span>
    </div>
  );
};

export default Loader;
