import React, { useEffect, useState } from "react";
import { getDoctors } from "../../utils/doctorUtils/doctorUtils";
import { SelectInput, TextInput } from "../Common/FormComponents";
import { useForm } from "../../hooks";
import { NoDataFound, SearchInput } from "../Common";
import DoctorData from "./DoctorData/DoctorData";
import { toast } from "react-toastify";
import { addConsultationAppointment } from "../../utils/bookConsultation/ConsultationUtils";
import { validateConsultaionForm } from "../../utils/bookConsultation/validateConsultationForm";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import { FaRupeeSign } from "react-icons/fa";

const ConsultationAppointmentForm = ({
  appointmentDetails = null,
  setDatasFn = null,
  is_follow_up,
  is_re_visit,
  setOpen,
}) => {
  const initialState = {
    name: appointmentDetails?.name || "",
    phone_number: appointmentDetails?.phone_number || "",
    email_id: appointmentDetails?.email_id || "",
    gender: appointmentDetails?.gender || "Select Gender",
    patient_id: appointmentDetails?.patient_id,
    disease: is_re_visit ? appointmentDetails?.disease || "" : "",
    payment_status: "Select Status",
    payment_method: "Select Method",
  };

  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [filterdDoctors, setFilteredDoctors] = useState([]);
  const [data, setData] = useState({
    date: null,
    slot: null,
    doctor: is_re_visit ? appointmentDetails?.doctor_id || null : null,
    time: null,
  });

  useEffect(() => {
    getDoctors()
      .then((res) => {
        setDoctors(res.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!is_re_visit) {
      getDepartments()
        .then((res) => {
          setDepartments(res.data.data);
        })
        .catch((err) => console.error(err));
    }
  }, [is_re_visit]);

  useEffect(() => {
    if (!is_re_visit) {
      const filteredData = doctors?.filter((doctor) => {
        const nameMatch = doctor?.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const idMatch = doctor?.doctor_id
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        const departmentMatch = selectedDepartment
          ? selectedDepartment === doctor?.department_id?._id
          : true;
        return (nameMatch || idMatch) && departmentMatch;
      });
      setFilteredDoctors(filteredData);
    }
  }, [doctors, is_re_visit, searchQuery, selectedDepartment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { doctor, time, slot, date } = data;
    if (validateConsultaionForm(formData, doctor, time, date, setError)) {
      setLoading(true);
      addConsultationAppointment(
        formData,
        doctor,
        time,
        slot,
        date,
        is_follow_up
      )
        .then((res) => {
          if (setDatasFn) setDatasFn();
          setOpen(false);
          toast(`Consultation booked successfully.`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.log(err);
          toast(`Consultation booking failed.`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Name"
          formName={"name"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <SelectInput
          data={formData}
          handleInput={handleInput}
          items={["Select Gender", "Male", "Female"]}
          name={"Gender"}
          formName={"gender"}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Phone Number"
          hide={true}
          formName={"phone_number"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TextInput
          name="Email"
          notShow={true}
          hide={true}
          formName={"email_id"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        {/* <SelectInput
          name="Platform"
          formName={"social_media"}
          items={[
            "Select Platform",
            "Facebook",
            "Instagram",
            "Website",
            "Snapchat",
            "LinkedIn",
            "X",
            "Google",
          ]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        /> */}
      </div>
      {!is_re_visit && (
        <div className="flex w-full h-fit justify-between gap-5">
          <div className="lable-and-filed">
            <label htmlFor={"department"} className="label">
              {"Department"}
            </label>
            <div
              className="input-coantiner"
              onClick={() => setError({ global: "" })}
            >
              <select
                className="select-option"
                name={"Department"}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                }}
              >
                <option value={""}>{"Select Department"}</option>
                {departments?.map((item, index) => (
                  <option key={index} value={item?._id}>
                    {item?.department}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <TextInput
            name="Disease"
            formName={"disease"}
            data={formData}
            handleInput={handleInput}
            setError={setError}
          />
        </div>
      )}
      <div className="flex w-full">
        <SearchInput
          placeholder={"Search Doctor"}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      {!is_re_visit && (filterdDoctors?.length > 0 ? (
        <div className="w-full min-h-[250px] overflow-y-auto">
          {filterdDoctors?.map((doctor, index) => (
            <DoctorData
              key={index}
              setErr={setError}
              data={doctor?._id === data?.doctor?._id ? data : null}
              doctor={doctor}
              setData={setData}
            />
          ))}
        </div>
      ) : (
        <div className="">
          <NoDataFound data={"doctors"} />
        </div>
      ))}
      {is_re_visit && data?.doctor && (
        <div className="w-full min-h-[250px] overflow-y-auto">
          <DoctorData
            is_re_visit={is_re_visit}
            setErr={setError}
            doctor={data?.doctor}
            data={data}
            setData={setData}
          />
        </div>
      )}
      {data?.doctor && (
        <div className="flex w-full h-fit items-center gap-1">
          <span className="">Consultancy Fee - </span>
          <span
            className={`${!data?.doctor?.price ? "text-red-500" : "text-black"
              } flex items-center gap-0.5`}
          >
            {data?.doctor?.price ? (
              <>
                <FaRupeeSign fontSize={12} />
                {data?.doctor?.price}
              </>
            ) : (
              "Doctor Not Added Yet!"
            )}
          </span>
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <SelectInput
          name="Payment Method"
          formName={"payment_method"}
          items={["Select Method", "Cash", "Online/UPI"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <SelectInput
          name="Status"
          formName={"payment_status"}
          items={["Select Status", "Pending", "Paid"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5 flex-auto items-end">
        <button
          className="w-full h-fit border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
          onClick={() => {
            clearForm();
            setOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          className="w-full h-fit border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
          disabled={loading}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default ConsultationAppointmentForm;